//全局样式
*{
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
//root
html,body,#root{
  height: 100%;
  width: 100%;
  font-size: 1.6rem;
  font-family: ui-sans-serif,system-ui,-apple-system,BlinkMacSystemFont,
  Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif,Apple Color Emoji,
  Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji;

}
:root{
  //全局颜色
  --global-color:"#ed7843";
}


::-webkit-scrollbar {
  width: 0;
  height: 0;
}


::-webkit-scrollbar-thumb {
  background-color: #888;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}
//文字超出以。。。显示
.overflow-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
/**************** and ******************/
//and modal icon
//.ant-modal .ant-modal-close {
//  top: 10px;
//  inset-inline-end:10px;
//}
//.ant-modal-title{
//  text-align: center;
//}
//radio  组件是font-size为0  这里设置继承父类font-size
//.ant-radio-group{
//  font-size: inherit;
//}

//去掉input为number时上下的图标
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
input[type="number"]{
  -moz-appearance: textfield;
}
