.P-Asr{
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  .header{
    height: 5%;
    width: 100%;
    font-size: 3rem;
    padding-left: 10px;
    box-shadow:  0 0 5px #ccc;
    line-height: 150%;
  }
  .content{
    width: 100%;
    height: 95%;
    display: flex;
    padding: 10px;
    .P-Asr-left{
      width: 30%;
      height: 100%;
      .P-Asr-left-image{
        width: 100%;
        height: 80%;
      }
    }
    .P-Asr-right{
      width: 70%;
      height: 100%;
      .P-Asr-right-record{
        width: 100%;
        height: 100%;
        //background-color: #e5e3e3;
        box-shadow: 0 0 5px #ccc;
        padding: 10px;
        font-size: 18px;
        overflow-y: auto;
      }
    }
  }
}
