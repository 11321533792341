.layout{
  width: 100%;
  height: 100%;
  background-color: #f5f7fa;
  //侧边+对话区
  .MyMain{
    width: 100%;
    height: 95%;
    display: flex;

    //侧边栏
    .left{
      height: 100%;
      width: 300px;

      .left_content{
        height: 94%;
      }
    }
  }

  //聊天里动画
  @keyframes center_dialogue_newly_increased {
    from{
      height: 75%;
    }
    to{
      height: 80%;
    }
  }
  @keyframes center_dialogue_subtract {
    from{
      height: 80%;
    }
    to{
      height: 75%;
    }
  }
  //输入框里动画
  @keyframes center_import_newly_increased {
    from{
      height: 15%;
    }
    to{
      height: 20%;
    }
  }
  @keyframes center_import_subtract {
    from{
      height: 20%;
    }
    to{
      height: 15%;
    }
  }

  //输入框里动画
  @keyframes center_import_input_newly_increased {
    from{
      height: 70%;
    }
    to{
      height: 100%;
    }
  }
  @keyframes center_import_input_subtract {
    from{
      height: 100%;
    }
    to{
      height: 70%;
    }
  }
  //正在回答的动画小图标
  @keyframes animationIcon_key {
    from{
      opacity: 1;
    }
    to{
      opacity: 0;
    }
  }
  .spin{
    height: 100%;
    .ant-spin-container{
      height: 100% !important;
    }
  }
}